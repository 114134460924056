html {
  background: #f8f9fb !important;
}

body {
  margin: 0;
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f8f9fb !important;
}

.login {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

*:focus {
  box-shadow: 0 0 0 1px #cbd5e0 !important;
  border-color: #cbd5e0 !important;
}

*[aria-invalid="true"] {
  box-shadow: 0 0 0 1px #e96b77 !important;
  border-color: #e96b77 !important;
}
*[disabled] {
  opacity: 0.6 !important;
}

.react-icon {
  display: inline !important;
}

.floating-dot {
  box-sizing: border-box;
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  border-radius: 4px;
  height: 8px;
  padding: 0;
  width: 8px;
  margin-right: 5px;
  margin-bottom: 1px;
}

.floating-dot.error {
  background: red;
}

.floating-dot.success {
  background: #05ce24;
}

.floating-dot.warning {
  background: #f4ba2c;
}

.chakra-modal__content-container {
  z-index: 2 !important;
}

.chakra-modal__overlay {
  z-index: 1 !important;
}
